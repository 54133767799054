<template>
  <div class="catalog" :class="{ 'loading': isItemsLoading }">
    <h1 class="title">
      Catalog
    </h1>
    <nav class="nav">
      <div v-if="searchData" class="nav-item">
        <a
          class="nav-link"
          :class="getActiveClass('All')"
          @click="setNewState({ title: 'All' })"
        >
          <span class="nav-link__title text-overflow">All</span>
          <span v-if="allCounts" class="nav-link__counter">{{ allCounts }}</span>
          <i class="nav-link__icon fas fa-chevron-right"/>
        </a>
      </div>
      <div v-for="(item, index) of nav" :key="`k${index}`" class="nav-item">
        <a
          class="nav-link"
          :class="getActiveClass(item.title)"
          @click="setNewState(item)"
        >
          <span class="nav-link__title text-overflow">{{ item.title }}</span>
          <span class="nav-link__counter">{{ item.count }}</span>
          <i class="nav-link__icon fas fa-chevron-right"/>
        </a>
        <div v-if="getSubMenu(item).length && checkSubMenuVisibility" class="nav-submenu">
          <a
            v-for="(subitem, index) of getSubMenu(item)"
            :key="`k${index}`"
            class="nav-link"
            :class="getActiveClass(subitem.title)"
            @click="setNewState(subitem, true)"
          >
            <span class="nav-link__title text-overflow">{{ subitem.title }}</span>
            <span class="nav-link__counter">{{ subitem.count }}</span>
            <i class="nav-link__icon fas fa-chevron-right"/>
          </a>
        </div>
      </div>
    </nav>

    <div class="divider"/>

    <stores-filter-list
      v-if="isShowFilterList && !isLoading"
    />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import StoresFilterList from '@/components/allStores/storesFilterList';

  export default {
    name: 'SearchCatalog',
    components: {
      StoresFilterList,
    },
    computed: {
      ...mapGetters({
        currentState: 'searchPage/currentState',
        searchData: 'searchPage/searchData',
        nav: 'searchPage/nav',
        checkSubMenuVisibility: 'searchPage/checkSubMenuVisibility',
        allCounts: 'searchPage/allCounts',
        isLoading: 'searchInStore/getIsLoading',
        isItemsLoading: 'searchPage/loading',
        getStoresArr: 'searchPage/getStoresArr',
      }),
      isShowFilterList() {
        if (!this.$route.query.providerId) {
          return false
        }

        return this.$route.query.providerId.length > 0;
      },
    },
    methods: {
      ...mapActions({
        changeLinkAction: 'searchPage/changeLink',
      }),
      getSubMenu(item) {
        return item?.catalog?.filter(item => item.count) || [];
      },
      setNewState(item, isSubMenu = false) {
        this.changeLinkAction({ item, isSubMenu });
      },
      getActiveClass(title) {
        return title === this.currentState ? 'active' : null;
      },
    },
  }
</script>
<style lang="scss" scoped>
  .catalog {
    height: 100%;
    padding: 30px 20px 30px 35px;
    background-color: $leftSideBar-background-color;

    &.loading {
      & > * {
        filter: blur(1px);
      }
    }

    .divider {
      width: calc(100% - 30px);
      height: 1px;
      background-color: $main-input-border-color;
      margin-bottom: 30px;
    }
  }

  .title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .nav {
    display: block;
  }

  .nav-item {
    position: relative;
    display: block;
  }

  .nav-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      font-weight: 500;
    }

    &__title {
      flex: 1;
    }

    &__counter {
      flex-basis: 38px;
      max-width: 38px;
      color: #A5AEBC;
    }

    &__icon {
      visibility: hidden;
      width: 6px;
    }

    &.active {
      .nav-link__title,
      .nav-link__counter,
      .nav-link__icon{
        font-weight: 500;
        color: $main-color;
      }

      .nav-link__icon {
        visibility: visible;
      }
    }
  }

  .nav-submenu {
    .nav-link {
      padding-left: 20px;
    }
  }
</style>