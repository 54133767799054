<template>
  <search-error
    v-if="isShowErrorPage"
    :header="'No Results'"
    :problem-text="`Sorry, your search « ${searchTerm} » did not match any result.`"
    :solution="'Please try again.'"
  />
  <div v-else>
    <div v-if="!isSubMenu" class="carousels">
      <b-spinner v-if="carouselsLoading"/>
      <template v-else>
        <div
          v-for="(carousel, i) of carousels"
          :key="carousel.title + i"
          class="carousels-item"
        >
          <template v-if="carousel.items.length">
            <div class="header">
              <h2 class="title">
                {{ !carousel.title.includes('Live') ? carousel.title : carousel.title.replace('Live', 'Events') }}
              </h2>
              <a class="link" @click="viewAll(carousel)">View All</a>
            </div>
            <div>
              <swiper-carousel
                :items="carousel.items"
                :items-type="carousel.carouselType"
                :config="getCarouselConfig(carousel.carouselType)"
                :show-on-search-page="true"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="isSubMenu" class="products">
      <b-spinner v-if="productsLoading"/>
      <template v-if="!productsLoading && productItems">
        <div class="product-back" @click="changeLink({ item: { title: 'All' }, isSubMenu: false })">
          <i class="far fa-chevron-left"/>
          Search
        </div>
        <div class="header">
          <h2 class="title">
            {{ !productItems.head.title.includes('Live') ? productItems.head.title : productItems.head.title.replace('Live', 'Events') }}
          </h2>
        </div>
        <div class="products-container" :class="productItems.head.itemsType || 'vertical'">
          <carousel-item
            v-for="item of productItems.products"
            :key="item.id"
            :item="item"
            :item-type="productItems.head.itemsType || 'vertical'"
            :show-on-search-page="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';
  import CarouselItem from 'src/components/swiper-carousel/carousel-item/carousel-item';
  import SearchError from 'src/components/searchError/searchError';

  import {
    DEFAULT_CAROUSEL_CONFIG,
    CIRCLE_CAROUSEL_ITEMS_CONFIG,
    VERTICAL_CAROUSEL_ITEMS_CONFIG,
  } from "src/config/carousel.config";

  export default {
    name: 'SearchContent',
    components: {
      SwiperCarousel,
      CarouselItem,
      SearchError,
    },
    computed: {
      ...mapGetters({
        isSubMenu: 'searchPage/isSubMenu',
        productsLoading: 'searchPage/productsLoading',
        carousels: 'searchPage/carousels',
        productItems: 'searchPage/productItems',
        carouselsLoading: 'searchPage/carouselsLoading',
      }),
      isShowErrorPage() {
        if(this.carousels?.length) {
          return !this.carousels.some(item => item.count);
        };

        return true;
      },
      searchTerm() {
        return this.$route.query.term;
      },
    },
    mounted() {
      this.setProductsArrayCopy();
      this.setProductPrices();

      this.$bus.$emit('setPriceInputRange');
    },
    methods: {
      ...mapActions({
        changeLink: 'searchPage/changeLink',
        setProductsArrayCopy: 'searchPage/setProductsArrayCopy',
        setProductPrices: 'searchPage/setProductPrices',
      }),
      viewAll(carousel = {}) {
        // if (!carousel.isDeep) {
        //   this.changeLink({ item: carousel, isSubMenu: carousel?.isDeep });
        //   return;
        // }

        this.changeLink({ item: carousel, isSubMenu: carousel?.isDeep });
      },
      getCarouselConfig(type) {
        switch (type) {
          case 'vertical':
            return {
              ...VERTICAL_CAROUSEL_ITEMS_CONFIG,
              breakpoints: {
                1760: { slidesPerView: 8 },
                1600: { slidesPerView: 7 },
                1440: { slidesPerView: 6 },
                1280: { slidesPerView: 5 },
                1120: { slidesPerView: 4 },
                960: { slidesPerView: 3 },
                800: { slidesPerView: 5 },
                640: { slidesPerView: 4 },
                480: { slidesPerView: 3 },
                300: { slidesPerView: 2 },
              },
            };
          case 'circle':
            return {
              ...CIRCLE_CAROUSEL_ITEMS_CONFIG,
              breakpoints: {
                1760: { slidesPerView: 9 },
                1600: { slidesPerView: 8 },
                1460: { slidesPerView: 7 },
                1300: { slidesPerView: 6 },
                1160: { slidesPerView: 5 },
                1020: { slidesPerView: 4 },
                960: { slidesPerView: 3 },
                800: { slidesPerView: 5 },
                640: { slidesPerView: 4 },
                480: { slidesPerView: 3 },
                300: { slidesPerView: 2 },
              },
            };
          default:
            return {
              ...DEFAULT_CAROUSEL_CONFIG,
              breakpoints: {
                1660: { slidesPerView: 5 },
                1400: { slidesPerView: 4 },
                880: { slidesPerView: 3 },
                480: { slidesPerView: 2 },
                300: { slidesPerView: 1 },
              },
            };
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 15px;
  }

  .title {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
  }

  .link {
    color: $main-color;
    cursor: pointer;
  }

  .product-back {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: $main-text-color;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        color: #5090AA;
      }
    }

    @media (min-width: 960px) {
      display: none;
    }

    i {
      margin-right: 15px;
    }
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    gap: 15px;

    &.vertical {
      grid-template-columns: repeat(auto-fit, minmax(159px, max-content));
    }

    &.circle {
      grid-template-columns: repeat(auto-fit, minmax(135px, max-content));
    }

    &.horizontal {
      grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    }
  }

  .carousels-item {
    border-bottom: 1px solid $segment-border-color;

    &:last-child {
      border-bottom: none;
    }
  }
</style>